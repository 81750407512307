// import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import LoginPage from "components/registration/LoginPage/LoginPage"
import theme from "components/layout/theme"

const Login = () => {
  return (
    <div style={{ background: theme.colors.primary }}>
      <SEO title="Login" />
      <LoginPage />
    </div>
  )
}

export default Login
