import { useMachine } from "@xstate/react"
import { Auth } from "aws-amplify"
import * as EmailValidator from "email-validator"
import React, { useState } from "react"
import { Machine } from "xstate"

import { FaArrowLeft, FaCog, FaEnvelope } from "react-icons/fa"
import ErrorText from "../ErrorText"
import { LightBlueAppButton } from "../buttons"
import RegistrationFormCard from "../cards/RegistrationFormCard"
import Form from "../form/Form"
import InputSeperator from "../form/InputSeperator"
import PleaseWaitButton from "../form/PleaseWaitButton"
import SubmitButton from "../form/SubmitButton"
import TextInput from "../form/TextInput"
import PasswordHelpText from "./PasswordHelpText"

interface iForgotPasswordForm {
  show: boolean
  Auth: typeof Auth
  onClickGoBack: () => void
  username: string
  onSuccess: () => void
}

interface iForgotPassword_EmailFormData {
  email: string
}

enum ErrorType {
  email,
  verification_code,
  password,
  confirm_password,
}

interface iForgotPasswordFormError {
  text: string | null
  type: ErrorType | null
}

interface iForgotPassword_PasswordFormData {
  verification_code: string
  password: string
  confirm_password: string
}

interface iForgotPassword_EmailFormView {
  loading: boolean
  onSubmit: (data: iForgotPassword_EmailFormData) => void
  error: iForgotPasswordFormError
  show: boolean
  email: string
}

interface iForgotPassword_PasswordFormView {
  show: boolean
  loading: boolean
  error: iForgotPasswordFormError
  onSubmit: (data: iForgotPassword_PasswordFormData) => void
  email: string
  onClickGoBack: () => void
}

const ForgotPasswordFormSubmitButton: React.FC<{ loading: boolean }> = ({
  loading,
}) => {
  if (!loading) return <SubmitButton title="Submit" Icon={<FaCog />} />
  return <PleaseWaitButton />
}

const ForgotPassword_EmailFormView: React.FC<iForgotPassword_EmailFormView> = ({
  loading,
  onSubmit,
  error,
  show,
  email,
}) => {
  if (!show) return null
  return (
    <RegistrationFormCard
      title_1="Ask Early Menopause"
      title_2="Forgot password"
    >
      <Form
        data={{ email: email } as iForgotPassword_EmailFormData}
        onSubmit={onSubmit}
      >
        {(data, setData) => (
          <div className="text-site-blue-dark">
            <ErrorText text={error.text} />
            <TextInput
              label="Email Address"
              onChange={(e: any) => setData("email", e.target.value)}
              placeholder="Enter your email address"
              value={data.email}
              type="text"
              error={error.type === ErrorType.email}
              disable={loading}
            />
            <InputSeperator />
            <InputSeperator />
            <ForgotPasswordFormSubmitButton loading={loading} />
          </div>
        )}
      </Form>
    </RegistrationFormCard>
  )
}

const ForgotPassword_PasswordFormView: React.FC<iForgotPassword_PasswordFormView> = ({
  show,
  loading,
  onSubmit,
  error,
  email,
  onClickGoBack,
}) => {
  if (!show) return null
  if (!email) onClickGoBack()
  return (
    <RegistrationFormCard
      title_1="Ask Early Menopause"
      title_2="Forgot password"
    >
      <Form
        data={
          {
            verification_code: "",
            password: "",
            confirm_password: "",
          } as iForgotPassword_PasswordFormData
        }
        onSubmit={onSubmit}
      >
        {(data, setData) => (
          <div className="text-site-blue-dark">
            <ErrorText text={error.text} />
            <p className="text-sm">
              We sent you a 6-digit verification code your email address.
            </p>

            <div className="flex my-2">
              <p
                onClick={onClickGoBack}
                className={`text-sm items-center flex bg-site-blue-dark
                    button-bg-site-blue-dark cursor-pointer text-white rounded
                    p-1 px-3`}
              >
                <span>
                  <FaEnvelope />
                </span>
                <span className="px-1" />
                <span>{email}</span>
              </p>
            </div>

            <TextInput
              label="Verification Code"
              onChange={(e: any) =>
                setData("verification_code", e.target.value)
              }
              placeholder="Enter the verification code"
              value={data.verification_code}
              type="text"
              error={error.type === ErrorType.verification_code}
              disable={loading}
            />
            <InputSeperator />
            <TextInput
              label="New password"
              onChange={(e: any) => setData("password", e.target.value)}
              placeholder={"Enter a new password"}
              value={data.password}
              type="password"
              error={error.type === ErrorType.password}
              disable={loading}
            />
            <InputSeperator />
            <PasswordHelpText show={data.password.length > 0} />
            <TextInput
              label="Confirm password"
              onChange={(e: any) => setData("confirm_password", e.target.value)}
              placeholder="Confirm your password"
              value={data.confirm_password}
              type="password"
              error={error.type == ErrorType.confirm_password}
              disable={loading}
            />
            <InputSeperator />
            <InputSeperator />
            <ForgotPasswordFormSubmitButton loading={loading} />
          </div>
        )}
      </Form>
      <InputSeperator />
      <p className="text-xs text-site-blue-dark">
        Cannot find the email? Check the spam mail.
      </p>
    </RegistrationFormCard>
  )
}

const ForgotPasswordForm: React.FC<iForgotPasswordForm> = ({
  Auth,
  show,
  onClickGoBack,
  username,
  onSuccess,
}) => {
  if (!show) return null
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<iForgotPasswordFormError>({
    text: null,
    type: null,
  })
  const [email, setEmail] = useState(username)

  const forgotPasswordMachine = Machine({
    id: "forgot-password-machine",
    initial: "emailForm",
    states: {
      emailForm: {
        on: {
          VERIFICATION_CODE_SENT: "changePasswordForm",
        },
      },
      changePasswordForm: {
        on: {
          PASSWORD_UPDATED: "done",
          GO_BACK: "emailForm",
        },
      },
      done: { type: "final" },
    },
  })
  const [currentForgotPasswordState, sendForgotPasswordState] = useMachine(
    forgotPasswordMachine
  )

  const onSubmitForEmailForm = ({ email }: iForgotPassword_EmailFormData) => {
    setError({
      text: null,
      type: null,
    })
    if (!email || !EmailValidator.validate(email)) {
      setError({
        type: ErrorType.email,
        text: "Please enter a valid email address",
      })
      return
    }
    setEmail(email)
    setLoading(true)

    Auth.forgotPassword(email)
      .then(() => {
        setLoading(false)
        sendForgotPasswordState("VERIFICATION_CODE_SENT")
      })
      .catch(err => {
        setError({
          type: null,
          text: err.message,
        })
        setLoading(false)
      })
  }

  const validateInputForPasswordForm = ({
    verification_code,
    password,
    confirm_password,
  }: iForgotPassword_PasswordFormData) => {
    if (!verification_code)
      return {
        type: ErrorType.verification_code,
        text: "Please enter the verification code",
      }
    if (!password)
      return {
        type: ErrorType.password,
        text: "Please enter a valid password",
      }
    if (!confirm_password)
      return {
        type: ErrorType.confirm_password,
        text: "Please verify your password",
      }
    if (confirm_password !== password)
      return {
        type: ErrorType.confirm_password,
        text: "The password does not match",
      }
    return null
  }

  const onSubmitForPasswordForm = ({
    verification_code,
    password,
    confirm_password,
  }: iForgotPassword_PasswordFormData) => {
    setError({
      type: null,
      text: null,
    })
    const err = validateInputForPasswordForm({
      verification_code,
      password,
      confirm_password,
    })
    if (err) {
      setError(err)
      return
    }
    setLoading(true)

    Auth.forgotPasswordSubmit(email, verification_code, password)
      .then(() => {
        setLoading(false)
        onSuccess()
      })
      .catch(err => {
        setLoading(false)
        if (err.code === "CodeMismatchException")
          setError({ type: ErrorType.verification_code, text: err.message })
        else if (err.code === "InvalidPasswordException")
          setError({ type: ErrorType.password, text: err.message })
        else setError({ type: null, text: err.message })
      })
  }

  return (
    <>
      <LightBlueAppButton
        className="shadow"
        title="Log In"
        Icon={<FaArrowLeft />}
        onClick={onClickGoBack}
      />
      <div className="py-2" />
      <ForgotPassword_EmailFormView
        loading={loading}
        onSubmit={onSubmitForEmailForm}
        error={error}
        show={currentForgotPasswordState.matches("emailForm")}
        email={email}
      />
      <ForgotPassword_PasswordFormView
        show={currentForgotPasswordState.matches("changePasswordForm")}
        loading={loading}
        error={error}
        onSubmit={onSubmitForPasswordForm}
        email={email}
        onClickGoBack={() => sendForgotPasswordState("GO_BACK")}
      />
    </>
  )
}

export default ForgotPasswordForm
