import React from "react"

import { FaCheckCircle } from "react-icons/fa"
import RegistrationFormCard from "../cards/RegistrationFormCard"

const RegistrationSuccessForm: React.FC<{ show: boolean; text: string }> = ({
  show,
  text,
}) => {
  if (!show) return null
  return (
    <RegistrationFormCard title_1="Ask Early Menopause" title_2="">
      <div className="flex justify-center mb-4 text-site-green">
        <FaCheckCircle style={{ fontSize: "42px" }} />
      </div>
      <p className="text-sm text-center text-site-blue-dark">{text}</p>
    </RegistrationFormCard>
  )
}

export default RegistrationSuccessForm
