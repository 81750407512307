import { useMachine } from "@xstate/react"
import { CognitoUserSession } from "amazon-cognito-identity-js"
import { Auth } from "aws-amplify"
import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { Machine } from "xstate"

import { FaUnlock } from "react-icons/fa"
import { Dashboard_InternalLink, SignUp_InternalLink } from "../../utils/urls"
import ErrorText from "../ErrorText"
import SuccessText from "../SuccessText"
import RegistrationFormCard from "../cards/RegistrationFormCard"
import Form from "../form/Form"
import InputSeperator from "../form/InputSeperator"
import PleaseWaitButton from "../form/PleaseWaitButton"
import SubmitButton from "../form/SubmitButton"
import TextInput from "../form/TextInput"
import ConfirmationForm from "./ConfirmationForm"
import ForcedChangePasswordForm from "./ForcedChangePasswordForm"
import ForgotPasswordForm from "./ForgotPasswordForm"
import RegistrationSuccessForm from "./RegistrationSuccessFrom"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import { withAmplifyAuth } from "./withAmplifyAuth"

interface iLoginFormData {
  email: string
  password: string
}

enum ErrorType {
  password,
  email,
}

interface iLoginFormError {
  text: string | null
  type: ErrorType | null
}

interface iLoginFormView {
  loading: boolean
  error: iLoginFormError
  onSubmit: (data: iLoginFormData) => void
  show: boolean
  onClickForgotPassword: () => void
  onChangeEmail: (email: string) => void
  username: string
  successText: string | null
}

const ConclusionText = () => (
  <p className="text-xs mt-4 text-site-blue-dark">
    Don't have an account?&nbsp;
    <span>
      <Link className="underline hover:font-bold" to={SignUp_InternalLink}>
        Sign up
      </Link>
    </span>
    &nbsp;or&nbsp;
    <span>
      <Link className="underline hover:font-bold" to="/">
        Home
      </Link>
    </span>
  </p>
)

const LoginFormSubmitButton: React.FC<{ loading: boolean }> = ({ loading }) => {
  if (!loading) return <SubmitButton title="Log In" Icon={<FaUnlock />} />
  return <PleaseWaitButton />
}

const LoginFormView: React.FC<iLoginFormView> = ({
  error,
  loading,
  onSubmit,
  show,
  onClickForgotPassword,
  onChangeEmail,
  username,
  successText,
}) => {
  if (!show) return null
  const loginFormData: iLoginFormData = {
    email: username,
    password: "",
  }

  return (
    <RegistrationFormCard title_1="Ask Early Menopause" title_2="Log in">
      <Form data={loginFormData} onSubmit={onSubmit}>
        {(data, setData) => {
          return (
            <div className="text-site-blue-dark">
              <ErrorText text={error.text} />
              <SuccessText text={successText} />
              <InputSeperator />
              <TextInput
                label="Email Address"
                onChange={(e: any) => {
                  setData("email", e.target.value)
                  onChangeEmail(e.target.value)
                }}
                placeholder={"Please enter your email address"}
                value={data.email}
                type="email"
                error={error.type === ErrorType.email}
                disable={loading}
              />
              <InputSeperator />
              <TextInput
                label="Password"
                onChange={(e: any) => setData("password", e.target.value)}
                placeholder="Please enter you password"
                value={data.password}
                type="password"
                error={error.type === ErrorType.password}
                disable={loading}
              />
              <div className="flex item-center justify-end pt-1">
                <p
                  onClick={onClickForgotPassword}
                  className="text-xs underline hover:font-bold cursor-pointer"
                >
                  Forgot password?
                </p>
              </div>
              <InputSeperator />
              <InputSeperator />
              <LoginFormSubmitButton loading={loading} />
            </div>
          )
        }}
      </Form>
      <ConclusionText />
    </RegistrationFormCard>
  )
}

const LoginForm: React.FC<{ Auth: typeof Auth; location?: any }> = ({
  Auth,
  location,
}) => {
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("")
  const [userAttributes, setUserAttributes] = useState<any>(null)

  const [error, setError] = useState<iLoginFormError>({
    text: null,
    type: null,
  })
  const [successText, setSuccessText] = useState<string | null>(null)

  const logInFlow = Machine({
    id: "login-flow-machine",
    initial: "logIn",
    states: {
      logIn: {
        on: {
          USER_NOT_CONFIRMED: "confirmation",
          FORCED_PASSWORD_CHANGE: "forcedPasswordChange",
          FORGOT_PASSWORD: "forgotPassword",
          LOGGED_IN: "done",
        },
      },
      forcedPasswordChange: {
        on: {
          LOGGED_IN: "done",
        },
      },
      forgotPassword: {
        on: {
          PASSWORD_CHANGED: "logIn",
          GO_BACK: "logIn",
        },
      },
      confirmation: {
        on: {
          USER_CONFIRMED: "logIn",
        },
      },
      done: { type: "final" },
    },
  })

  const [currentLogInState, sendLogInState] = useMachine(logInFlow)

  useEffect(() => {
    if (window && window.location) {
      const [key, value] = window.location.search.slice(1).split("=")
      if (key && key === "logInState" && value) {
        sendLogInState(value)
        Auth.currentAuthenticatedUser().then(data => {
          setUserAttributes(data)
        })
      }
    }
  }, [])
  const validateField = (email: string, password: string) => {
    if (!email)
      return {
        type: ErrorType.email,
        text: "Please enter a valid email address",
      }
    if (!password)
      return {
        type: ErrorType.password,
        text: "Please enter the password",
      }
    else return null
  }

  const onSubmit = ({ email, password }: iLoginFormData) => {
    if (loading) return
    setError({
      text: null,
      type: null,
    })
    setSuccessText(null)
    const err = validateField(email, password)
    if (err) {
      setError(err)
      return
    }
    setLoading(true)
    setUsername(email)

    Auth.signIn(email, password)
      .then(data => {
        setLoading(false)
        if (
          data.challengeName &&
          data.challengeName === "NEW_PASSWORD_REQUIRED"
        ) {
          console.log("Forced password change... required")
          setUserAttributes(data)
          sendLogInState("FORCED_PASSWORD_CHANGE")
          syncProfileWithSessionStorage({ ...data, email })
          Auth.currentAuthenticatedUser()
            .then(cognitoUserData => {
              try {
                const group =
                  cognitoUserData.signInUserSession.idToken.payload[
                    "cognito:groups"
                  ]
                const roleStr = group ? group[0] : "Patient"
                window.sessionStorage.setItem("UserRole", roleStr)
              } catch (e) {
                console.log("Authenticate User:", e)
              }
            })
            .catch(e => {
              console.log("error: ", e)
            })
        }
        onReceiveUserSession(data)
      })
      .catch((err: any) => {
        setLoading(false)
        if (err.code === "NotAuthorizedException") {
          setError({
            type: null,
            text: "Incorrect email or password",
          })
          return
        }
        if (err.code === "UserNotConfirmedException") {
          console.log("User not confirmed")
          sendLogInState("USER_NOT_CONFIRMED")
          return
        }
        setError({
          type: null,
          text: err.message,
        })
      })
  }

  const onPasswordChangeSuccess = () => {
    setSuccessText("Password successfully changed.")
    window.location.href = "/login/"
  }

  // When the user has logged in from any flow. The session will come over here and it MUST
  const onReceiveUserSession = (session: CognitoUserSession) => {
    sendLogInState("LOGGED_IN")
  }

  if (currentLogInState.matches("done")) {
    const search = window.location.search
    if (search) {
      const [key, value] = search.slice(1).split("=")
      if (key === "returnUrl" && value) {
        navigate(decodeURIComponent(value))
      } else {
        navigate(Dashboard_InternalLink)
      }
    } else {
      navigate(Dashboard_InternalLink)
    }
  }

  return (
    <>
      <LoginFormView
        loading={loading}
        error={error}
        successText={successText}
        username={username}
        onSubmit={onSubmit}
        show={currentLogInState.matches("logIn")}
        onClickForgotPassword={() => sendLogInState("FORGOT_PASSWORD")}
        onChangeEmail={(email: string) => setUsername(email)}
      />
      <ForcedChangePasswordForm
        show={currentLogInState.matches("forcedPasswordChange")}
        onSuccess={onPasswordChangeSuccess}
        userAttributes={userAttributes}
        Auth={Auth}
      />
      <ConfirmationForm
        Auth={Auth}
        Username={username}
        show={currentLogInState.matches("confirmation")}
        onSuccess={() => {
          setSuccessText("You account has been confirmed.")
          sendLogInState("USER_CONFIRMED")
        }}
      />
      <ForgotPasswordForm
        Auth={Auth}
        show={currentLogInState.matches("forgotPassword")}
        onClickGoBack={() => sendLogInState("GO_BACK")}
        username={username}
        onSuccess={() => {
          sendLogInState("PASSWORD_CHANGED")
          setSuccessText("Password successfully changed.")
        }}
      />
      <RegistrationSuccessForm
        show={currentLogInState.matches("done")}
        text="You have successfully logged in. Please wait while we redirect you."
      />
    </>
  )
}

export default withAmplifyAuth(LoginForm)
